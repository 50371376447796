@import '@styles/styles.scss';

.btnCopyWrapper {
    .txtCopy {
        display: none;
    }

    .btnCopy {
        &.MuiButton-text {
            height: 26px;
            width: 26px;
            min-width: 26px;
            padding: 0;
            border-radius: 5px;
        }
    }
}
