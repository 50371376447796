@import '@styles/styles.scss';

.resultedWrapper {
    .mainInfosWrapper {
        // width: 900px;
        padding: 40px 30px 0;

        @include tablet {
            // width: 700px;
        }

        @include mobileLarge {
            // width: auto;
        }
    }

    .orderInfos {
        margin-top: 0;
    }
}
