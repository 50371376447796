@import '@styles/variables.scss';

.itemTag {
    font-size: $FONTSIZE_20;

    button {
        border-radius: 32px;
        background-color: $COLOR_EMERALD;
    }
}
