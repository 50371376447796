@import '@styles/variables.scss';

.actionsDialogContent {
    .dialogActionGroup {
        flex-direction: column-reverse;

        .btn {
            height: 54px;
            width: 235px;
            margin-bottom: 10px;
        }
    }
}
