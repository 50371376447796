@import '@styles/styles.scss';

.stepper.stepperVertical {
    margin: 0 45px 0 15px;
    .step {
        &.currStep,
        &.completedStep {
            .stepItm {
                opacity: 1;

                .stepIndex {
                    color: $COLOR_WHITE;
                    background-color: $primaryColor;
                }
            }
        }

        &.completedStep {
            .stepVerticalLine {
                background-color: $primaryColor;
            }
        }

        .stepItm {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            font-size: $FONTSIZE_18;
            font-weight: bold;
            color: $primaryColor;
            opacity: 0.3;
            height: 30px;

            .stepIndex {
                display: flex;
                justify-content: center;
                align-items: center;
                min-width: 36px;
                min-height: 36px;
                border-radius: 50%;
                border: 3px solid;
                margin-right: 20px;
                border: 3px solid $primaryColor;
                color: $primaryColor;
                background-color: $COLOR_WHITE;
            }

            .stepName {
                min-width: 100px;
            }
        }

        .stepVerticalLine {
            width: 3px;
            height: 60px;
            margin-left: 16px;
            background-color: transparent;
        }
    }
}
