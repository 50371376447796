@import '@styles/styles.scss';

.stepper {
    &.stepperHorizontal {
        display: flex;
        justify-content: space-between;
        max-width: 550px;
        margin: 59px auto 40px;

        .step {
            &.currStep,
            &.completedStep {
                .stepItm {
                    opacity: 1;

                    .stepIndex {
                        color: $COLOR_WHITE;
                        background-color: $primaryColor;
                    }
                }
            }

            &.completedStep {
                .stepHorizontalLine {
                    background-color: $primaryColor;
                }
            }

            .stepItm {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                flex-direction: column;
                min-width: 180px;
                font-size: $FONTSIZE_18;
                color: $primaryColor;
                background-color: $COLOR_WHITE;
                color: $primaryColor;
                opacity: 0.3;
                text-align: center;

                .tickIco {
                    width: 30px;
                    height: 30px;
                }

                .stepConnector {
                    z-index: 1;
                    position: relative;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-bottom: 28px;
                }
                .stepIndex {
                    font-size: $FONTSIZE_30;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 50%;
                    border: 3px solid $primaryColor;
                    color: $primaryColor;
                    background-color: $COLOR_WHITE;
                    min-width: 36px;
                    min-height: 36px;
                }
            }

            .stepHorizontalLine {
                z-index: -1;
                position: absolute;
                left: calc(75%);
                width: 280px;
                height: 8px;
                background-color: transparent;
            }
        }

        @include mobileLarge {
            margin-top: 0;
            margin-bottom: 30px;

            .step {
                .stepItm {
                    font-size: $FONTSIZE_14;
                    min-width: 150px;

                    .stepConnector {
                        margin-bottom: 5px;
                    }

                    .stepIndex {
                        font-size: $FONTSIZE_16;
                        width: 28px;
                        height: 28px;
                        border-width: 2px;
                    }
                }

                .stepHorizontalLine {
                    width: 150px;
                    height: 5px;
                }
            }
        }

        @include mobile {
            max-width: 350px;
            .step {
                .stepItm {
                    min-width: 120px;
                    .stepName {
                        max-width: 105px;
                    }
                    .stepConnector {
                        margin-bottom: 14px;
                    }
                }

                .stepHorizontalLine {
                    width: 120px;
                }
            }
        }

        @include mobileSmall {
            max-width: 300px;
            .step {
                .stepItm {
                    min-width: auto;
                }
                .stepHorizontalLine {
                    width: 80px;
                }
            }
        }
    }
}
