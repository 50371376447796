@import '@styles/styles.scss';

.wrapper {
    .amountInfosWrapper {
        margin-top: 0;
    }

    &.sellPayingTwoStepSimple,
    &.buyPayingManual {
        .amountInfosWrapper {
            margin-top: $SPACING_LAYOUT_MEDIUM;
        }
        .companyInfosWrapper {
            margin-top: 0;
        }
        .itemField {
            &.realAmount,
            &.unitCount {
                .itemFieldLeft {
                    .fieldLabelWrapper {
                        min-width: auto !important;
                    }
                }
            }
        }
    }
}
